.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 2rem;
  text-align: center;
}

.card {
  display: flex;
  margin-top: 3rem;
  text-align: left;
}