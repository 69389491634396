.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 2rem;
}

.title {
  margin: 1.5rem 0;
  font-size: 3rem;
  font-weight: bold;
}

.hr {
  width: 70%;
  height: 1px;
  background-color: var(--rich-black);
  border: none;
}

.pieInfo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 1rem 0 1rem 0;
  padding: 1rem;
}

.pie {
  margin: 2rem;
}

.left {
  flex-wrap: wrap;
}

.right {
  flex-wrap: wrap-reverse;
}

.description {
  text-align: center;
  margin-top: 2rem;
  font-size: 1.25rem;
  max-width: 600px;
  margin-right: 1.5rem;
  margin-left: 1.5rem;
}