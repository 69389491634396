.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 2rem;
}

.logo {
  max-width: 200px;
}

.title {
  margin-top: 0;
}

.hr {
  width: 70%;
  height: 1px;
  background-color: var(--rich-black);
  border: none;
}

.aboutParagraph {
  margin: 1rem 0;
  font-size: 1.25rem;
  max-width: 600px;
}

.profileImg {
  margin-top: 1rem;
  max-width: 100px;
  height: auto;
  border-radius: 50%;
  float: left;
  shape-outside: circle();
  margin-right: 1rem;
}

.placeholder {
  opacity: 0.5;
  margin-top: 25px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: var(--rich-black);
}

.skills {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  width: 75%;
}

.cardLeft {
  align-self: flex-start;
  margin-top: 3.5rem;
  margin-left: 15%;
  margin-bottom: 3.5rem;
}

.cardRight {
  align-self: flex-end;
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
  margin-right: 15%;
}
