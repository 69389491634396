.card {
  background-color: var(--rich-black);
  width: 20rem;
  color: white;
  padding: 1rem;
  position: relative;
  border-radius: 10px;
}

.title {
  margin-top: 1.5rem;
  font-size: 1.75rem;
  font-weight: bold;
}

.triangle {
  position: absolute;
  top: 0;
  left: 0;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 0);
  background-color: var(--fire);
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.icon {
  position: absolute;
  top: 0.5rem;;
  right: 0.5rem;
  font-size: 2.5rem;
}