.card {
  background-color: var(--rich-black);
  color: white;
  width: 25rem;
  min-width: 15rem;
  max-width: 70%;
  height: fit-content;
  position: relative;
}

.right {
  border-top-left-radius: 10px;
}

.left {
  border-top-right-radius: 10px;
}

.cardHeader {
  background-color: var(--fire);
  color: var(--rich-black);
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
}

.cardBody {
  padding: 1rem;
  padding-top: 1.25rem;
  font-size: 1.5rem;
}

.iconContainer {
  display: flex;
  width: fit-content;
  border-radius: 50%;
  background-color: var(--fire);
  padding: 1rem;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
}

.icRight {
  right: 0;
  transform: translate(25%, -25%);
}

.icLeft {
  left: 0;
  transform: translate(-25%, -25%);
}

.icon {
  display: flex;
  font-size: 2rem;
  color: white;
}