.container {
  background-color: var(--rich-black);
  width: 100vw;
}

.projects {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 10rem 2rem;
}

.project {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  width: 450px;
}

h1 {
  color: var(--fire);
}

.utah {
  width: 50%;
  border-radius: 10px;
}

.liveIn {
  width: 500px;
  border-radius: 10px;
}

.anonChat {
  width: 200px;
  border-radius: 10px;
}
