.pieContainer {
  width: 335px;
  height: 335px;
  border-radius: 50%;
  background-color: var(--rich-black);
  overflow: hidden;
  text-align: center;
}

.centerTitle {
  font-weight: bold;
  font-size: 1.4rem;
}

.callToAction {
  font-size: 1rem;
  font-weight: bold;
  color: var(--eton-blue);
  opacity: 0.7;
  position: absolute;
  bottom: 10%;
  left: 30%;
}