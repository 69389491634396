.bar {
  width: 100vw;
  height: var(--navbar-height);
  background-color: #01161e;
  display: flex;
  color: var(--eton-blue);
  justify-content: center;
  position: fixed;
  flex-direction: row;
  overflow: none;
  z-index: 1;
}

.small {
  justify-content: right;
  flex-direction: column;
}

.toggle {
  height: fit-content;
}

.menuIcon {
  position: absolute;
  padding: 19px;
  height: fit-content;
  width: fit-content;
  right: 0;
  cursor: pointer;
  border-radius: 5px;
}

.menu {
  background-color: var(--rich-black);
  margin-top: var(--navbar-height);
}

.smallTitle {
  font-weight: bold;
  position: absolute;
  top: 0;
  left: 0;
  padding: 1rem;
  color: var(--eton-blue);
  font-size: 1.5rem;
}

