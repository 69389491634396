html, body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Inconsolata', monospace;
  --navbar-height: 60px;
  --fire: #EB5E28;
  --rich-black: #01161E;
  --eton-blue: #80CFA9;
  --charcoal: #3F4B4E;
  --opal: #8CBCB9;
  background-color: var(--charcoal);
  overflow-x: hidden;
  width: 100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: inherit;
  text-decoration: none;
}
