.item {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
  padding: 0.5rem;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.25rem;
}

.text {
  padding: 0.5rem;
}

.underline {
  height: 5px;
  width: 100%;
  background-color: var(--eton-blue);
}